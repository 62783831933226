import React, { useEffect, useRef, useReducer } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { initialData, baseReducer } from "react-local-state-management-helper";

import * as htmlToImage from "html-to-image";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { initialState } from "./utils/state";
import {
  initializeState,
  getAddressFromLatLng,
  dateFormat,
  handleDownload,
  handleRefresh,
  highlightNewWords,
  convertLatLngToDMS,
} from "./utils";
import Wrapper from "../../components/Wrapper";
import Banner from "./components/Banner";
import Card from "./components/Card";
import Save from "./components/Save";

const PhotoDetails = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const State = initializeState(location.state.item, initialState);
  const Reducer = baseReducer(State);

  const [state, dispatch] = useReducer(Reducer, initialData(State));

  const domEl = useRef(null);
  const wrapEl = useRef(null);
  const saveButton = useRef(null);
  const refreshButton = useRef(null);

  useEffect(() => {
    getAddressFromLatLng(
      location.state.item.lat,
      location.state.item.lon,
      dispatch
    );
  }, [location]);

  return (
    <Wrapper>
      {(state.isLoading) ? (<div>Loading ...</div>) : (
      <><div style={{ flex: "0 0 100%" }} ref={wrapEl} className="details-wrap">
          <Banner
            token={state.name}
            imgSrc={state.image}
            dispatch={dispatch}
            state={state} />
          <Card
            title="Current Address"
            state={state}
            dispatch={dispatch}
            modalFor={state.openAddrModel}
            location={location}
            loadAddress={true}
            desc={state.editAddr
              ? highlightNewWords(state.currentAddr, state.addr, 'black')
              : state.addr}
            focus={state.editAddr}
            action="SET_OPENADDRMODEL"
            editable={true} />

          <Card
            title="Current Location"
            state={state}
            dispatch={dispatch}
            modalFor={state.openGeoModel}
            location={location}
            focus={state.editGeo}

            desc={state.editGeo
              ? `latitude - ${highlightNewWords(
                String(location.state.item.lat),
                String(state.lat),
                'black'
              )} <br/> longitude - ${highlightNewWords(
                String(location.state.item.lon),
                String(state.lon),
                'black'
              )}`
              : `latitude - ${state.lat} <br/> longitude - ${state.lon}`}
            action="SET_OPENGEOMODEL"
            editable={true}
            refreshCord={true}
            RefresRef={refreshButton}
            handleRefresh={() => handleRefresh(toast, dispatch, refreshButton, navigate)} />
          <Card
            title="Date"
            state={state}
            dispatch={dispatch}
            modalFor={false}
            location={location}
            desc={dateFormat(new Date())}
            action=""
            editable={false} />
          <ToastContainer />
        </div><Save
            state={state}
            termsConditionHref="/terms-and-conditions"
            downloadUrl={state.name}
            saveRef={saveButton}
            imgRef={domEl}
            highlightNewWords={highlightNewWords}
            convertLatLngToDMS={convertLatLngToDMS}
            location={location}
            handleSubmit={() => handleDownload(
              state,
              wrapEl,
              domEl,
              saveButton,
              dispatch,
              navigate,
              location,
              htmlToImage,
              toast
            )} />  
        </> )}
        { state.isDownloading && (
          <div className="isDownloading">
            <div>
             <div className="lds-ripple"><div></div><div></div></div> Downloading please wait... 
            </div>
          </div>
        )}  
        { state.isDownloaded && (
          <div className="isDownloaded">
            <div className="details-wrap text-center">
             <h2 className="text-center">Thank you.<br/> Your photo + geo tag is now ready</h2>
             <button onClick={()=>navigate("/")}>OK</button>
            </div>
          </div>
        )}  
    </Wrapper>
  );
};

export default PhotoDetails;
