import React from "react";
import { useNavigate } from "react-router-dom";
import Wrapper from "../components/Wrapper";


const styles = {
  textLeft: {
    textAlign: 'left !important',
    justifyContent: 'flex-start !important'
  },
  fixed: {
    position: 'fixed',
    width: '100%',
    height: 70,
    bottom: 0,
    left: 0
  },
  relative: {
    position: 'relative',
    width: '100%',
    height: 60,
    maxWidth: 428,
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
    margin: 'auto',
    backgroundColor: 'rgba(255,255,255,0.8)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'flex-end'
  },
  button: {
    backgroundColor: '#7D7BE6',
    padding: 8,
    border: 0,
    boxShadow: 'none',
    borderRadius: 8,
    color: 'white'
  }
}
function TermsAndConditions() {
  const navigate = useNavigate();
  const backToHome = () => {
    navigate('/');
  }
  return (
    <Wrapper>
      <div style={styles.textLeft} className="terms-and-conditions">
        <h1 >Terms and Conditions</h1>
        <p>
          Thank you for using our app. Before proceeding, please carefully read
          and understand the following terms and conditions that govern your use
          of the application
        </p>
        <h2>1. Acceptance of Terms</h2>
        <p>
          By downloading, installing, or using our app, you agree to be bound by
          these terms and conditions. If you do not agree with any part of these
          terms, please refrain from using the application.
        </p>
        <h2>2. Privacy and Data Usage</h2>
        <h3>2.1 Collection of Information</h3>
        <p>
          Our app may request access to your location, camera, and storage.
          However, please note that we do not save or store any personal data or
          information obtained through these permissions. We respect your
          privacy and prioritize the security of your data.
        </p>
        <h3>2.2 Location Information</h3>
        <p>
          When using location-based features within the app, such as maps or
          geolocation services, we may access your device's location data. This
          information is used solely for providing the intended functionality
          and enhancing your user experience.
        </p>
        <h3>2.3 Camera and Storage</h3>
        <p>
          The app may require access to your device's camera and storage to
          enable certain features, such as capturing photos or saving files
          locally. However, rest assured that we do not transmit, share, or
          store any media captured or stored on your device without your
          explicit consent.
        </p>
        <h2>3. Intellectual Property</h2>
        <p>
          The app and all its contents, including but not limited to text,
          images, graphics, logos, and software, are the property of the app's
          owners or its licensors. You agree not to reproduce, distribute,
          modify, or create derivative works of any material without obtaining
          explicit written permission.
        </p>
        <h2>4. Disclaimer of Liability</h2>
        <h3>4.1 Use at Your Own Risk</h3>
        <p>
          The app is provided "as is" without any warranties or guarantees of
          any kind, either expressed or implied. You acknowledge and agree that
          your use of the app is at your own risk, and we shall not be held
          liable for any damages, losses, or issues arising from its use.
        </p>
        <h3>4.2 Third-Party Services and Content</h3>
        <p>
          The app may include links, integrations, or interactions with
          third-party services or content. We do not endorse or assume any
          responsibility for the availability, accuracy, or legality of such
          third-party services or content. Your interactions with third-party
          services are solely between you and the respective providers.
        </p>
        <h2>5. Modifications and Termination</h2>
        <p>
          We reserve the right to modify, suspend, or terminate the app or any
          part thereof, including these terms and conditions, at any time
          without prior notice or liability.
        </p>
        <h2>6. Governing Law</h2>
        <p>
          These terms and conditions shall be governed by and construed in
          accordance with the laws of [Jurisdiction]. Any disputes arising from
          the use of the app or related to these terms shall be subject to the
          exclusive jurisdiction of the courts in [Jurisdiction].
        </p>
        <p>
          By using the app, you acknowledge that you have read, understood, and
          agreed to these terms and conditions. If you have any questions or
          concerns, please contact us at [contact email/website].
        </p>
        <p>Last updated: [Date]</p>
        <div style={styles.fixed}>
          <div style={styles.relative}> 
            <button style={styles.button} type="button" onClick={() => backToHome()}>Back to Home</button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default TermsAndConditions;
