import React from 'react';
import TermAndCondition from '../../../components/TermAndCondition';

const styles = {
    hidden: {
      display: "none",
    },
    button: {
      width: "100%",
      backgroundColor: "#7D7BE6",
      color: "white",
      borderRadius: 12,
      padding: "20px 15px",
      display: "inline-block",
      textAlign: "center",
      textTransform: "uppercase",
      fontSize: 20,
      lineHeight: "24px",
			fontWeight: 300
    },
		fullWidth: {
			flex: '0 0 100%'
		}
  };

function TakePhoto(props) {
    return (
        <div style={styles.fullWidth}>
          <TermAndCondition Href={props.termsConditionHref} />
          <input
            type="file"
            name="picture"
            accept="image/*"
            capture="user"
            onChange={props.handleChange}
            id="capture-image"
            style={styles.hidden}
          />
          <label htmlFor="capture-image" style={styles.button}>
            Take Photo
          </label>
        </div>
    );
}

export default TakePhoto;