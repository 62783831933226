import React, { useReducer, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useCurrentPosition } from "react-use-geolocation";
import { ToastContainer, toast } from "react-toastify";
import Wrapper from "../../components/Wrapper";
const FetchingLocation = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const [position, error] = useCurrentPosition();
  if (!position && !error) {
    toast.warning("Please wait", {
      position: toast.POSITION.TOP_CENTER,
    });
  }

  if (error) {
    let msg;
    {
      error.message === "User denied Geolocation"
        ? (msg = "Location access is disabled. Please enable to proceed.")
        : (msg = `${error.message}`);
    }
    navigate("/?errorLocation");
  }
  if (position) {
    navigate(`/get-started`, {
      state: {
        item: {
          name: location.state.item.name,
          source: location.state.item.source,
          lat: position.coords.latitude,
          lon: position.coords.longitude,
        },
      },
    });
  }
  return (
    <Wrapper>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="lds-hourglass"></div>
      </div>
      <ToastContainer />
    </Wrapper>
  );
};

export default FetchingLocation;
