import React from "react";

const styles = {
  width: "100%",
  height: "100%",
  color: "#000",
  padding: 20,
  maxWidth: 428,
  margin: "auto",
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

function Wrapper({ children, attributes }) {
  return <div style={styles} {...attributes}>{children}</div>;
}

export default Wrapper;