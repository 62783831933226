import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const styles = {
  fullWidth: {
    flex: "0 0 100%",
  },
  label: {
    fontWeight: 500,
    marginBottom: 5,
    display: "inline-block",
  },
  input: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    minWidth: 250,
    color: "#606060",
    borderColor: "#606060",
    borderWidth: "0 0 1px 0",
    borderRadius: 0,
    marginBottom: 10,
    boxShadow: "none",
    padding: "5px 10px",
  },
};
function Card(props) {
  const onOpenModal = () =>
    props.dispatch({ type: props.action, payload: true });
  return (
    <div style={styles.fullWidth} className="card">
      <h2>
        {props.title}
        {props.state.edit && props.editable ? (
          <button className="edit-button" onClick={onOpenModal}></button>
        ) : (
          ""
        )}
        {props.refreshCord ? (
          <button
            ref={props.RefresRef}
            className="refresh"
            onClick={props.handleRefresh}
          ></button>
        ) : (
          ""
        )}
      </h2>
      {props.loadAddress && !props.state.addr.length ? (
        <div className="loading-addr" style={{background: '#ccc',padding: 2}}>
          <div className="lds-ripple">
            <div></div>
            <div></div>
          </div>{" "}
          loading address please wait ...{" "}
        </div>
      ) : (
        <p
          className={props.focus ? "edited" : ""}
          dangerouslySetInnerHTML={{ __html: props.desc }}
        ></p>
      )}
      <Modal
        open={props.modalFor}
        onClose={() => onOpenModal}
        center
        classNames="card"
      >
        <div>
          <h2>{props.title}</h2>
          {props.state?.openAddrModel && (
            <textarea
              value={props.state.addr}
              onChange={(e) => {
                props.dispatch({ type: "SET_ADDR", payload: e.target.value });
                props.dispatch({ type: "SET_EDITADDR", payload: true });
              }}
            ></textarea>
          )}
          {props.state?.openGeoModel && (
            <>
              <div>
                <label style={styles.label}>latitude: </label>
                <input
                  type="number"
                  value={props.state.lat}
                  style={styles.input}
                  onChange={(e) => {
                    props.dispatch({ type: "SET_EDITGEO", payload: true });
                    props.dispatch({
                      type: "SET_LAT",
                      payload: e.target.value,
                    });
                  }}
                />
              </div>
              <div>
                <label style={styles.label}>longitude: </label>
                <input
                  type="number"
                  value={props.state.lon}
                  style={styles.input}
                  onChange={(e) => {
                    props.dispatch({ type: "SET_EDITGEO", payload: true });
                    props.dispatch({
                      type: "SET_LON",
                      payload: e.target.value,
                    });
                  }}
                />
              </div>
            </>
          )}
          <div style={{ textAlign: "right" }}>
            <button
              type="button"
              onClick={() => {
                if (props.state?.openAddrModel) {
                  props.dispatch({ type: "SET_EDITADDR", payload: false });
                  props.dispatch({
                    type: "SET_ADDR",
                    payload: props.state.currentAddr,
                  });
                }
                if (props.state?.openGeoModel) {
                  props.dispatch({ type: "SET_EDITGEO", payload: false });
                  props.dispatch({
                    type: "SET_LAT",
                    payload: props.location.state.item.lat,
                  });
                  props.dispatch({
                    type: "SET_LON",
                    payload: props.location.state.item.lon,
                  });
                }
                props.dispatch({ type: props.action, payload: false });
              }}
            >
              CANCEL
            </button>
            <button
              type="button"
              onClick={() => {
                props.dispatch({ type: props.action, payload: false });
              }}
            >
              OK
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Card;
