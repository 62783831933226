import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Wrapper from "../components/Wrapper";
import { ToastContainer, toast } from "react-toastify";

const styles = {
  flex: {
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    background: "rgba(125, 123, 230, 0.5)",
  },
  input: {
    width: "100%",
    height: 40,
    marginBottom: 10,
    padding: "0 10px",
    lineHeight: 40,
    boxShadow: "none",
    border: 0,
  },
  button: {
    width: "100%",
    backgroundColor: "#7D7BE6",
    color: "white",
    borderRadius: 12,
    padding: "10px",
    display: "inline-block",
    textAlign: "center",
    textTransform: "uppercase",
    fontSize: 20,
    lineHeight: "24px",
    fontWeight: 300,
    boxShadow: "none",
    border: 0,
  },
};

function TokenValidate() {
  const [token, setToken] = useState("");
  const [validate, setValidate] = useState(false);
  const navigate = useNavigate();
  const data = {
    token: token,
    action: "validate",
  };

  const validateToken = (e) => {
    e.preventDefault();
    setValidate(true);
    fetch(
      `https://photoapp-backend.gailabs.com/api/generate-token-validate-token?_format=json`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          action: "validate",
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => {
        if (response.ok) {
          sessionStorage.setItem("myToken", token);
          setValidate(false);
          navigate("/");
        } else {
          toast.error("Invalid Token", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setValidate(false);
        toast.error(error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  return (
    <Wrapper>
      <form onSubmit={validateToken} style={styles.flex}>
        <div style={{ flex: "0 0 100%", maxWidth: "calc(100% - 40px)" }}>
          <div>
            <h2 style={{ textAlign: "center" }}>
              Token Entry Required:
              <br /> <small>Please Provide Your Access Token</small>
            </h2>
            <input
              type="text"
              onChange={(e) => setToken(e.target.value)}
              required
              style={styles.input}
            />
          </div>
          <div style={{ textAlign: "center" }}>
            <button type="submit" style={styles.button} disabled={validate}>
              {validate ? (
                <>
                  <div className="lds-ripple">
                    <div></div>
                    <div></div>
                  </div>{" "}
                  Processing...{" "}
                </>
              ) : (
                "Submit"
              )}
            </button>
            <hr />
            <p style={{lineHeight: 1}}>
             <small>Please ensure that you have a network connection and that your
              location service is enabled</small>
            </p>
          </div>
        </div>
      </form>
      <ToastContainer />
    </Wrapper>
  );
}

export default TokenValidate;
