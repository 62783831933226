import React from "react";
import {Route, Routes, BrowserRouter as Router} from 'react-router-dom';
import Home from "./screens/Home";
import PhotoDetails from "./screens/PhotoDetails";
import TermsAndConditions from "./screens/TermsAndConditions";
import FetchingLocation from "./screens/FetchingLocation";
import TokenValidate from "./screens/TokenValidate";
import './App.css';

function App() {
  return (
    <div className="App">
      <Router>
          <Routes>
            <Route element={<Home/>} path="/"></Route>
            <Route element={<FetchingLocation/>} path="/fetching-location"></Route>
            <Route element={<PhotoDetails/>} path="/get-started"></Route>
            <Route element={<TermsAndConditions/>} path="/terms-and-conditions"></Route>
            <Route element={<TokenValidate/>} path="/token"></Route>
          </Routes>

      </Router>
    </div>
  );
}

export default App;
