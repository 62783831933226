import React from "react";
import TermAndCondition from "../../../components/TermAndCondition";
import { dateFormat } from "../utils";
import Map from "../components/Map";

const styles = {
  button: {
    width: "100%",
    backgroundColor: "#7D7BE6",
    color: "white",
    borderRadius: 12,
    padding: "20px 15px",
    display: "block",
    textAlign: "center",
    textTransform: "uppercase",
    fontSize: 20,
    lineHeight: "24px",
    fontWeight: 300,
    border: 0,
    boxShadow: "none",
    marginBottom: 10,
  },
  fullWidth: {
    flex: "0 0 100%",
  },
  domEl: {
    display: "none",
    width: "100%",
    position: "relative",
  },
};

function Save(props) {


  return (
    <div style={styles.fullWidth}>
      <TermAndCondition Href={props.termsConditionHref} />
      <button
        type="button"
        onClick={props.handleSubmit}
        style={styles.button}
        ref={props.saveRef}
        disabled = {props.state.addr.length ? false: true }
      >
        Save
      </button>
      <div id="domEl" ref={props.imgRef} style={styles.domEl}>
        <div className="img-wrap">
          <img src={props.state.image} style={{ maxWidth: "100%" }} alt="potrait" />
          <div className="img-coords">
            <div style={{ display: "flex", alignItems: 'flex-end', padding: '10px 20px 10px 15px' }}>
              <div style={{ flex: "0 0 100%; max-width: 50%;" }}>
                {props.state.editAddr ? (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: props.highlightNewWords(
                        props.state.currentAddr,
                        props.state.addr,
                        'white'
                      ),
                    }}
                  ></p>
                ) : (
                  <p>{props.state.addr}</p>
                )}
                <p>
                  {dateFormat(new Date()).split(" ")[1]}{" "}
                  {dateFormat(new Date()).split(" ")[2]}
                  <br />
                  {dateFormat(new Date()).split(" ")[0].toUpperCase()}
                </p>
              </div>
              <div style={{ flex: "0 0 50%" }}>
                {props.state.editGeo ? (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: `${props.convertLatLngToDMS(
                        props.state.lat,
                        props.state.lon,
                        "lat"
                      )} |  ${props.convertLatLngToDMS(
                        props.state.lat,
                        props.state.lon,
                        "lon"
                      )}<br/>${props.highlightNewWords(
                        String(props.location.state.item.lat),
                        String(props.state.lat),
                        'white'
                      )} / ${props.highlightNewWords(
                        String(props.location.state.item.lon),
                        String(props.state.lon),
                        'white'
                      )}`,
                    }}
                    style={{textAlign: 'right'}}
                  ></p>
                ) : (
                  <p style={{textAlign: 'right'}}>
                    {props.convertLatLngToDMS(
                      props.state.lat,
                      props.state.lon,
                      "lat"
                    )}{" "}
                    |{" "}
                    {props.convertLatLngToDMS(
                      props.state.lat,
                      props.state.lon,
                      "lon"
                    )}
                    <br />
                    {props.state.lat} / {props.state.lon}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <hr style={{margin: 0,height: 10, background: 'black'}}/>
        <Map state={props.state} />
      </div>
    </div>
  );
}

export default Save;
