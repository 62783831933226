import React from "react";
import { Link } from "react-router-dom";

const styles = {
  link: {
    color: "rgba(0, 0, 255, 0.71)",
    fontSize: 14,
    lineHeight: "16px",
    textDecoration: "none",
  },
	mb: {
		marginBottom: 10
	}
};
function TermAndCondition(props) {
  return <p style={styles.mb}>
		<Link to={props.Href} style={styles.link}>Terms-and-Conditions</Link>
	</p>;
}

export default TermAndCondition;
