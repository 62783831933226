//get Address from lat and long
const getAddressFromLatLng = (latt, long, dispatch) => {
  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latt},${long}&key=AIzaSyArpuPTYS6ZeBfJpDrONKnn1KX4jKEVhj0`;
  return fetch(url)
    .then((response) => response.json())
    .then((data) => {
      dispatch({
        type: "SET_ADDR",
        payload: data.results[0].formatted_address,
      });
      dispatch({
        type: "SET_CURRENTADDR",
        payload: data.results[0].formatted_address,
      });
    })
    .catch((error) => {
      console.error(error);
    });
};

const getAddressFromLatLngOSM = (latt, long, dispatch) => {
  const url = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latt}&lon=${long}`;
  return fetch(url)
    .then((response) => response.json())
    .then((data) => {
      dispatch({ type: "SET_ADDR", payload: data.display_name });
      dispatch({ type: "SET_CURRENTADDR", payload: data.display_name });
    })
    .catch((error) => {
      console.error(error);
    });
};

const updateAddressOSM = async (address, dispatch, latitude, longitude) => {
  try {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
    );
    const data = await response.json();

    if (data && data.display_name) {
      const updatedAddress = { ...data };
      updatedAddress.display_name = `${address}`;
      dispatch({ type: "SET_ADDR", payload: data.display_name });
      const overpassQuery = `[out:json][timeout:25];
        (
          node(${updatedAddress.lon},${updatedAddress.lat},${updatedAddress.lon},${updatedAddress.lat})["display_name"];
        );
        out body;`;

      await performOverpassRequest(overpassQuery);
    }
  } catch (error) {
    console.error("Error updating address:", error);
  }
};

const performOverpassRequest = async (query) => {
  try {
    const overpassResponse = await fetch(
      "https://overpass-api.de/api/interpreter",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `data=${encodeURIComponent(query)}`,
      }
    );

    const overpassData = await overpassResponse.json();
    console.log(overpassData);
  } catch (error) {
    console.error("Error performing Overpass request:", error);
  }
};
//download image
const handleDownload = async (
  state,
  wrapEl,
  domEl,
  saveButton,
  dispatch,
  navigate,
  location,
  htmlToImage,
  toast
) => {
  if (state.addr !== "" && state.lat !== "" && state.lon !== "") {
    dispatch({ type: "SET_ISLOADING", payload: false });
    dispatch({ type: "SET_ISDOWNLOADING", payload: true });
    wrapEl.current.style.display = "none";
    domEl.current.style.display = "inline-block";
    saveButton.current.style.display = "none";
    //convert domEl to jpeg image 
    const dataUrl = await htmlToImage.toJpeg(domEl.current);
    // download image
    const link = document.createElement("a");
    link.download = `${state.name}.jpeg`;
    link.href = dataUrl;
    fetch(`https://photoapp-backend.gailabs.com/api/generate-token-validate-token?_format=json`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'action': 'validate'
      },
      body: JSON.stringify({
        "token": sessionStorage.getItem('myToken'),
        "action": "submit"
      }),
    })
      .then(response => {
         if (response.ok) {
          console.log("hello")
          link.click();
          sessionStorage.removeItem('myToken')
          dispatch({ type: "SET_ISDOWNLOADING", payload: false });
          dispatch({ type: "SET_ISDOWNLOADED", payload: true });
         } else {
          sessionStorage.removeItem('myToken')
          navigate("/token")
         }
    })
      .catch(error => {
        console.error('Error:', error);
        toast.error(error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  } else {
    wrapEl.current.style.display = "block";
    domEl.current.style.display = "none";
    saveButton.current.style.display = "block";
    dispatch({ type: "SET_ISLOADING", payload: false });
    if (state.addr === "") {
      toast.error("Please provide an address.", {
        position: toast.POSITION.TOP_CENTER,
      });
      dispatch({ type: "SET_EDITADDR", payload: false });
    }
    if (state.lat === "" || state.lon === "") {
      toast.error("Please provide both latitude/longitude.", {
        position: toast.POSITION.TOP_CENTER,
      });
      dispatch({ type: "SET_LAT", payload: location.state.item.lat });
      dispatch({ type: "SET_LON", payload: location.state.item.lon });
      dispatch({ type: "SET_EDITGEO", payload: false });
    }
  }
};

//Format Date String

const dateFormat = (date) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const day = String(date.getDate()).padStart(2, "0");
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  const amOrPm = hours >= 12 ? "PM" : "AM";

  hours %= 12;
  hours = hours || 12;

  const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds} ${amOrPm}`;
  return formattedDate;
};
// refresh cordinates
const handleRefresh = (toast, dispatch, refreshButton, navigate) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        refreshButton.current.classList.add("loading");
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        getAddressFromLatLng(latitude, longitude, dispatch);
        // toast.success(<>New coordinates:<br/>{latitude}, {longitude}  (after refresh)</>, {
        //   position: toast.POSITION.TOP_CENTER,
        // });
        dispatch({ type: "SET_LAT", payload: latitude });
        dispatch({ type: "SET_LON", payload: longitude });
        setTimeout(() => {
          refreshButton.current.classList.remove("loading");
          navigate("/");
        }, 1000);
      },
      (error) => {
        console.error("Error:", error.message);
        navigate(`/?User denied Geolocation`)
      }
    );
  } else {
    console.error("Geolocation is not supported by this browser.");
    toast.error("Geolocation is not supported by this browser.", {
      position: toast.POSITION.TOP_CENTER,
    });
  }
};

const highlightNewWords = (oldString, newString, colorCode) => {
  // Split the old string into an array of words
  const oldWords = oldString.split(" ");

  // Split the new string into an array of words
  const newWords = newString.split(" ");

  // Find the new words by comparing with the old words
  const newWordsSet = new Set(
    newWords.filter((word) => !oldWords.includes(word))
  );

  // Generate the highlighted new string
  const highlightedNewString = newWords
    .map((word) => {
      if (newWordsSet.has(word)) {
        return `<span style="border-bottom: 1px solid ${colorCode}; margin-bottom: 1px; display: inline-block;">${word}</span>`;
      } else {
        return word;
      }
    })
    .join(" ");

  return highlightedNewString;
};
//intilize state

const initializeState = (locationStateItem, initialState) => {
  return {
    ...initialState,
    name: locationStateItem.name,
    image: locationStateItem.source,
    lat: locationStateItem.lat,
    lon: locationStateItem.lon,
  };
};

//conversion Decimal to DMS
const convertLatLngToDMS = (latitude, longitude, result) => {
  const lat = Math.abs(latitude);
  const lng = Math.abs(longitude);

  const latDegrees = Math.floor(lat);
  const latMinutes = Math.floor((lat - latDegrees) * 60);
  const latSeconds = Math.floor((lat - latDegrees - latMinutes / 60) * 3600);

  const lngDegrees = Math.floor(lng);
  const lngMinutes = Math.floor((lng - lngDegrees) * 60);
  const lngSeconds = Math.floor((lng - lngDegrees - lngMinutes / 60) * 3600);

  const latDirection = latitude >= 0 ? "N" : "S";
  const lngDirection = longitude >= 0 ? "E" : "W";
  if (result === "lat") {
    return `${latDegrees}°${latMinutes}'${latSeconds}"${latDirection}`;
  } else {
    return `${lngDegrees}°${lngMinutes}'${lngSeconds}"${lngDirection}`;
  }
};
export {
  initializeState,
  getAddressFromLatLng,
  dateFormat,
  handleDownload,
  handleRefresh,
  highlightNewWords,
  getAddressFromLatLngOSM,
  updateAddressOSM,
  convertLatLngToDMS,
};
