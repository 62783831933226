import React from "react";
const styles = {
  banner: {
    width: "100%",
    backgroundImage: `url(/banner.svg)`,
    backgroundSize: "100%",
    backgroundRepeat: "no-repeat",
  },
  fullWidth: {
    flex: "0 0 100%",
  },
  heading: {
    flex: "0 0 100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

function Detail() {
  return (
    <>
      <h1 style={styles.heading}>AkshayCS<br/>Photo + GeoTag App</h1>
      <div style={styles.fullWidth}>
        <div style={styles.banner} className="banner"></div>
        <h2>Application to take photo with:</h2>
        <ul>
          <li>. GPS Co-ordinates</li>
          <li>. Address</li>
          <li>. Current Date</li>
        </ul>
      </div>
    </>
  );
}

export default Detail;
