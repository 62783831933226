/*The reportWebVitals.js file is typically included in a React.js
 application to report performance metrics to a third-party service, 
 such as Google Analytics. It is not necessary for the basic functioning of the app,
but it can provide valuable insights into the app's performance.

If you don't need to report web vitals to a third-party service, 
you can safely remove the reportWebVitals.js file from your React app.
However, if you do need to report performance metrics, 
it's recommended to keep the file and configure it to report to your desired service.

It's worth noting that the reportWebVitals.js file is a relatively
 small file and removing it will not have a significant impact on the app's 
 overall performance. However, if you are looking to optimize your app's performance, 
 there may be other areas of your codebase that are worth focusing on first.
*/


const reportWebVitals = onPerfEntry => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
};

export default reportWebVitals;
