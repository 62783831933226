const initialState = {
  image: "",
  lat: "",
  lon: "",
  name: "",
  addr: "",
  editAddr: false,
  editGeo: false,
  openAddrModel: false,
  openGeoModel: false,
  edit: false,
  currentAddr: "",
  isLoading: false,
  isDownloading: false,
  isDownloaded: false,
};

export { initialState };
