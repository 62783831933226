const token = (dateString) => {
  let nowDate = dateString;
  return (
    nowDate.getFullYear().toString().substring(2) +
    String(nowDate.getMonth() + 1).padStart(2, "0") +
    String(nowDate.getDate()).padStart(2, "0") +
    String(nowDate.getHours()).padStart(2, "0") +
    String(nowDate.getMinutes()).padStart(2, "0") +
    String(nowDate.getSeconds()).padStart(2, "0") +
    "-"+
    Math.floor(Math.random() * 100)
      .toString()
      .padStart(2, "0")
  );
};

const handleCapture = (target,dispatch,navigate) => {
  if (target.files) {
    if (target.files.length !== 0) {
      const file = target.files[0];
      const newUrl = URL.createObjectURL(file);
      dispatch({ type: "SET_SOURCE", payload: newUrl }); 
      navigate(`/fetching-location`, {
        state: {
          item: {
            name: token(new Date()),
            source: newUrl
          },
        },
      });
    }
  }
};

export { token, handleCapture };
