import React from "react";
import ToggleButton from "react-toggle-button";

const styles = {
  fullWidth: {
    flex: "0 0 100%",
  },
  img: {
    width: "100%",
    height: "341px",
    objectFit: "contain",
    backgroundColor: "rgba(125,123,230,0.5)",
    borderRadius: 12,
  },
	toggle: {
		display: 'flex',
		alignItems: 'center',
		marginTop: 12,
		marginBottom: 12
	},
	span: {
   fontSize: 14,
	 paddingLeft: 20
	},
	colors: {
    activeThumb: {
      base: "#ffffff",
    },
    inactiveThumb: {
      base: "#ffffff",
    },
    active: {
      base: "#7D7BE6",
      hover: "#7D7BE6",
    },
    inactive: {
      base: "#d9d9d9",
      hover: "#d9d9d9",
    },
  },
};
function Banner(props) {
  return (
    <div style={styles.fullWidth}>
      <h1>{props.token}</h1>
      <img src={props.imgSrc} alt="banner" style={styles.img} />
			<div style={styles.toggle}>
			<ToggleButton
            value={props.state.edit}
            inactiveLabel={" "}
            activeLabel={""}
            onToggle={(value) => {
							props.dispatch({ type: "SET_EDIT", payload: !value });
            }}
            colors={styles.colors}
          />
				<span style={styles.span}>Edit Address, GPS</span>
			</div>

    </div>
  );
}

export default Banner;
