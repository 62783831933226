import React, { useReducer, useEffect } from "react";
import { initialData, baseReducer } from "react-local-state-management-helper";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { initialState } from "./utils/state";
import { handleCapture } from "./utils";
import Wrapper from "../../components/Wrapper";
import Detail from "./components/Detail";
import TakePhoto from "./components/TakePhoto";

const Home = () => {
  const State = initialData(initialState);
  const Reducer = baseReducer(initialState);
  const [state, dispatch] = useReducer(Reducer, State);
  const navigate = useNavigate();
  const validateToken = () => {
    fetch(`https://photoapp-backend.gailabs.com/api/generate-token-validate-token?_format=json`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'action': 'validate'
      },
      body: JSON.stringify({
        "token": sessionStorage.getItem('myToken'),
        "action": "validate"
      }),
    })
      .then(response => {
         if (response.ok) {
          console.log("valid token")
         } else {
          sessionStorage.removeItem('myToken')
          console.log("invalid token")
         }
    })
      .catch(error => {
        console.error('Error:', error);
        toast.error(error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  useEffect(() => {
    const handleWindowResize = () => {
      const isDesktop =
        window.innerWidth > 1024 &&
        !/Mobi|Tablet|iPad|iPhone|Android/.test(navigator.userAgent);

      if (isDesktop) {
        toast.warning("This App works best on Mobile.", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    };
    // Call the handler on initial page load
    handleWindowResize();
    
    // Add event listener for resize event
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  useEffect(()=>{
    if (sessionStorage.getItem('myToken')) {
      validateToken()
    } else {
      navigate("/token")
    }
  },[]);
  useEffect(() => {
    const currentURL = window.location.href;
    const searchParams = new URLSearchParams(new URL(currentURL).search);
    if (searchParams && searchParams.toString() !== '') {
      toast.error(`Location access is disabled. Please enable to proceed.`, {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      console.log('No query parameters found.');
      console.log(state.timer,state.time,'check')
    }
  }, []);


  return (
    <Wrapper>
      <Detail />
      <TakePhoto
        handleChange={(e) =>
          handleCapture(e.target, dispatch, navigate, state)
        }
        state = {state}
        dispatch = {dispatch}
        termsConditionHref="/terms-and-conditions"
      />
      <ToastContainer />
    </Wrapper>
  );
};

export default Home;
