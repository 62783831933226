import React from "react";
import GoogleMapReact from "google-map-react";

const Map = ({ state }) => {
  const latitude = state.lat;
  const longitude = state.lon;
  const apiKey = "AIzaSyArpuPTYS6ZeBfJpDrONKnn1KX4jKEVhj0";
  const markerPosition = `${latitude},${longitude}`;
  const imageUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${markerPosition}&zoom=17&size=600x400&markers=${markerPosition}&key=${apiKey}`;
  return (
    <div
      style={{
        height: "200px",
        width: "100%",
        position: "relative",
        display: "inline-block",
        overflow: "hidden"
      }}
    >
      <img
        src={imageUrl}
        alt="map"
        style={{
          position: "absolute",
          width: "100%",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-46%)",
          objectFit: "cover",
        }}
      />
    </div>
  );
};

export default Map;
